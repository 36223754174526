<template>
    <b-pagination v-if="pagination && pagination.next_page > 1" id="pagination" :align="align" :pills="true"
                  v-model="pagination.current_page"
                  :total-rows="pagination.total_items"
                  hide-goto-end-buttons
                  :per-page="pagination.per_page" :next-class="'hidden'" :prev-class="'hidden'"/>
</template>

<script>
    import {BPagination} from 'bootstrap-vue'
    export default {
        components: {BPagination},
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                default: null
            },
            align: {
                type: String,
                default: 'left'
            }
        },
        computed: {
            pagination: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        },
        watch: {
            'pagination.current_page'() {
                this.$scrollToTop()
            }
        }
    }
</script>

<style scoped>
</style>
<style>
#pagination.b-pagination-pills .page-item .page-link {
  width: 49px;
  height: 49px;
  background-color: #F8F8F8;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  color: black;
}
#pagination.b-pagination-pills .page-item.active .page-link {
  background-color: #72A5D8 !important;
  color: white;
}
</style>
